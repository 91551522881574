import React, { FC } from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import { responseType } from '@helpers/guarantee.helper';

import RegisterResponseTemplate from '../register-response-template/RegisterResponseTemplate.component';
import { PageType } from '@constants/pageType.constants';

interface IRegisterCompleted {
  email: string;
  linkPath: string;
}

const RegisterCompleted: FC<IRegisterCompleted> = ({email, linkPath}) => {
  const intl = useIntl();
  
  return (
    <RegisterResponseTemplate
      header={intl.formatMessage({ id: 'guarantee__responseHeader' })}
      descriptionPart1={intl.formatMessage({ id: 'guarantee__responseDescriptionPart1' })}
      descriptionPart2={intl.formatMessage({ id: 'guarantee__responseDescriptionPart2' })}
      buttonText={intl.formatMessage({ id: 'guarantee__responseButton' })}
      email={email}
      isCompleted
      additionalText={intl.formatMessage({ id: 'guarantee__additionalText'})}
      isLinkEnable
      linkText={intl.formatMessage({ id: 'guarantee__responseLink' })}
      linkPath={linkPath}
      result={responseType.Success}
      buttonTargetPage={PageType.START}
    />
  );
};

export default RegisterCompleted;
