import React, { FC } from 'react';

import { Segment } from '@components/segment/Segment';
import RegisterCompleted from '@components/form-response/register-completed/RegisterCompleted.component';
import { GatsbySeo } from 'gatsby-plugin-next-seo/lib';
import { useIntl } from 'gatsby-plugin-intl';

interface IRegistrationComplete {
  location: {
    state?: {
      email: string,
      link: string
    }
  }
}

const RegistrationCompleteTemplate: FC<IRegistrationComplete> = (props) => {
  const intl = useIntl();

  return (
    <>
      <GatsbySeo
        noindex={true}
        nofollow={true}
        title={`Avionaut - ${intl.formatMessage({ id: 'metaTitle__registrationComplete' })}`}
      />
      <Segment>
        <RegisterCompleted
          email={props.location.state ? props.location.state.email : ''}
          linkPath={props.location.state ? props.location.state.link : ''}
        />
      </Segment>
    </>
  )
};

export default RegistrationCompleteTemplate;
