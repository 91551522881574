import React from 'react';
import { Link as GatsbyIntlLink, useIntl } from 'gatsby-plugin-intl';

interface LinkProps {
  children: React.ReactNode;
  to: string;
  [key: string]: any;
}

export const Link = ({ children, to, ...props }: LinkProps) => {
  const intl = useIntl();
  
  return (
      <GatsbyIntlLink hrefLang={intl.locale} to={to} {...props}>
        {children}
      </GatsbyIntlLink>
    )
};
