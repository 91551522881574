import React, {FC, memo} from 'react';
import {GatsbyLinkProps, Link} from 'gatsby';

import {useIntl} from 'gatsby-plugin-intl';

import {PageType, dynamicPageTypes} from '@constants/pageType.constants';
import {getLink} from '@helpers/linking.helper';

interface IMultilingualLink extends Omit<Omit<Omit<GatsbyLinkProps<{}>, 'key'>, 'ref'>, 'to'> {
  pageType: PageType;
  productSlug?: string;
  productColor?: string;
}

export const MultilingualLink: FC<IMultilingualLink> = ({pageType, productSlug, productColor, children, ...rest}) => {
  const intl = useIntl();

  let path: string;

  if (dynamicPageTypes.includes(pageType) && typeof productSlug !== `undefined` && typeof productColor !== `undefined`) {
    // produkt z kolorem kolor
    path = getLink(intl.locale, pageType, productSlug, productColor);
  } else if (dynamicPageTypes.includes(pageType) && typeof productSlug !== `undefined`) {
    // produkt
    path = getLink(intl.locale, pageType, productSlug);
  } else {
    // link w oparciu o page type
    path = getLink(intl.locale, pageType);
  }

  return <Link hrefLang={intl.locale} to={path} {...rest} >{children}</Link>;
};

export default memo(MultilingualLink);
