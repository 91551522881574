import React, { FC } from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import { PageType } from '@constants/pageType.constants';

import { Button } from '../../button/Button';

import Post from '@assets/svg/post.svg';
import Check from '@assets/svg/check.svg';
import Stop from '@assets/svg/stop.svg';

import s from './RegisterResponseTemplate.module.scss';

interface IRegisterResponseTemplate {
  header: string;
  descriptionPart1: string;
  descriptionPart2: string | undefined;
  linkPath: string | undefined;
  linkText: string | undefined;
  buttonText: string;
  isCompleted: boolean | undefined;
  additionalText: string;
  isLinkEnable: boolean | undefined;
  email: string | undefined;
  result: number;
  isSNDuplicated: boolean;
  isSNDuplicatedText: string;
  buttonTargetPage: PageType
}

const RegisterResponseTemplate: FC<IRegisterResponseTemplate> = (props) => {
  const {
    header,
    descriptionPart1,
    descriptionPart2,
    linkPath,
    linkText,
    buttonText,
    isCompleted,
    additionalText,
    isLinkEnable,
    email,
    result,
    isSNDuplicated,
    isSNDuplicatedText,
    buttonTargetPage
  } = props;

  const intl = useIntl();

  const renderSmallIcon = {
    0: <Check className={s.registerResponseTemplate__iconSmallSvg} />,
    1: <Stop className={s.registerResponseTemplate__iconSmallSvg} />,
    2: <Stop className={s.registerResponseTemplate__iconSmallSvg} />,
  };

  return (
    <div className={s.registerResponseTemplate}>
      <div className={s.registerResponseTemplate__icons}>
        <div className={s.registerResponseTemplate__icon}>
          <Post className={s.registerResponseTemplate__iconSvg} />
        </div>
        <div className={s.registerResponseTemplate__iconSmall}>
          {renderSmallIcon[result]}
        </div>
      </div>
      <div className={s.registerResponseTemplate__header}>
        <p className={s.registerResponseTemplate__headerText}>{header}</p>
      </div>
      <div className={s.registerResponseTemplate__description}>
        <span className={s.registerResponseTemplate__descriptionText}>{descriptionPart1}</span>
        {email && <span
          className={`${s.registerResponseTemplate__descriptionText} ${s.registerResponseTemplate__descriptionBold}`}>{email}</span>}
        {descriptionPart2 && <span className={s.registerResponseTemplate__descriptionText}>{descriptionPart2}</span>}
      </div>
      {
        isSNDuplicated &&
        <div className={s.registerResponseTemplate__duplicateText}>{isSNDuplicatedText}</div>
      }
      {
        isCompleted &&
        <div className={s.registerResponseTemplate__additionalText}>{additionalText}</div>
      }
      {
        isLinkEnable &&
        <div className={s.registerResponseTemplate__link}>
          <a href={linkPath} hrefLang={intl.locale} className={s.registerResponseTemplate__linkText} aria-label={linkText}>
            {linkText}
          </a>
        </div>
      }
      <div className={s.registerResponseTemplate__button}>
        <Button pageType={buttonTargetPage}>{buttonText}</Button>
      </div>
    </div>
  );
};

export default RegisterResponseTemplate;
