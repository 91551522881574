export enum responseType {
  Success= 0,
  PartialSuccess= 1,
  Fail= 2
}

export const additionalProductsIncludesExtendedWarranty = [
  'Avionaut Pixel Anex',
  'Avionaut Pixel Venicci',
  'Avionaut Pixel TFK',
  'Avionaut Pixel My Junior',
  'Avionaut Cosmo Venicci',
  'Avionaut Cosmo Anex',
  'Avionaut Cosmo Venicci',
  'Avionaut Cosmo Moon',
  'Avionaut Cosmo Mee Go',
  'Avionaut Cosmo Aro',
  'Avionaut Ultralite Venicci',
  'Avionaut Aerofix Venicci',
  'Avionaut Aerofix My Junior',
  'Avionaut Isofix IQ Venicci',
  'Avionaut Isofix IQ TFK',
  'Avionaut Isofix IQ My Junior',
  'Avionaut Sky Q'
]
