import React, { FC, ReactNode } from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import { PageType } from '@constants/pageType.constants';
import { MultilingualLink } from '../multilingual-link/MultilingualLink.component';

import { Link } from '../link/Link';

import s from './Button.module.scss';

interface IButton {
  children: ReactNode;

  pageType?: PageType;
  slug?: string;
  href?: string;
  white?: boolean;
  yellow?: boolean;
  red?: boolean;
  thin?: boolean;
  small?: boolean;
  sand?:boolean;
  responsive?: boolean;
  isSubPage?: boolean;

  onClick?(): void;
}

export const Button: FC<IButton> = (props) => {
  const {
    children,
    pageType,
    slug,
    href,
    white,
    thin,
    small,
    sand,
    responsive,
    yellow,
    red,
    isSubPage,
    onClick,
  } = props;

  const intl = useIntl();
  
  const isLink = typeof href !== 'undefined';
  const isExternal = isLink && /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(href || '');

  if (typeof pageType !== `undefined`) {
    return (
      <MultilingualLink
        pageType={pageType}
        productSlug={slug}
        onClick={onClick}
        className={`${s.button} ${white ? s.white : ''} ${sand ? s.sand : ''} ${thin ? s.thin : ''} ${small ? s.small : ''} ${responsive ? s.responsive : ''} ${yellow ? s.yellow : ''} ${red ? s.red : ''}`}
      >
        {children}
      </MultilingualLink>
    );
  }

  if (isSubPage) {
    return (
      <a
        className={`${s.button} ${white ? s.white : ''} ${sand ? s.sand : ''} ${thin ? s.thin : ''} ${small ? s.small : ''} ${responsive ? s.responsive : ''} ${yellow ? s.yellow : ''} ${red ? s.red : ''}`}
        href={href}
        hrefLang={intl.locale}
        aria-label={'button'}
      >
        {children}
      </a>
    );
  }

  if (isExternal) {
    return (
      <a
        className={`${s.button} ${white ? s.white : ''} ${sand ? s.sand : ''} ${thin ? s.thin : ''} ${small ? s.small : ''} ${responsive ? s.responsive : ''} ${yellow ? s.yellow : ''} ${red ? s.red : ''}`}
        target="_blank" rel="noopener noreferrer nofollow"
        href={href}
        hrefLang={intl.locale}
        aria-label={'button'}
      >
        {children}
      </a>
    );
  }

  if (isLink) {
    return (
      <Link
        className={`${s.button} ${white ? s.white : ''} ${sand ? s.sand : ''} ${thin ? s.thin : ''} ${small ? s.small : ''} ${responsive ? s.responsive : ''} ${yellow ? s.yellow : ''} ${red ? s.red : ''}`}
        to={href || '#'}>
        {children}
      </Link>
    );
  }

  return (
    <button
      className={`${s.button} ${white ? s.white : ''} ${sand ? s.sand : ''} ${thin ? s.thin : ''} ${small ? s.small : ''} ${responsive ? s.responsive : ''} ${yellow ? s.yellow : ''} ${red ? s.red : ''}`}
      onClick={onClick}>
      {children}
    </button>
  );
};
