import React, { ReactNode } from 'react';

import s from './Container.module.scss';

interface IContainer {
  children: ReactNode;
}

export const Container = ({ children }: IContainer) => (
  <section className={s.container}>{children}</section>
);
